import cx from 'classnames';
import { useState } from 'react';

import { navigationConfig } from '@hubcms/brand';
import { type NavItem } from '@hubcms/domain-navigation';
import { Center } from '@hubcms/ui-center';
import { NavigationLogo, NavigationMenu, NavigationUserMenu } from '@hubcms/ui-navigation-2';
import { useScrollListener } from '@hubcms/utils-browser';

import { NavigationItem } from '../NavigationItem';
import { SubscriptionButton } from '../SubscriptionButton';

import styles from './header.module.scss';

type HeaderDesktopServicesProps = {
  navigationItems: NavItem[];
  loginUrl: string;
  subscriptionItem?: NavItem;
  userMenuItems: NavItem[];
};

export function HeaderDesktopServices({
  navigationItems,
  loginUrl,
  subscriptionItem,
  userMenuItems,
}: HeaderDesktopServicesProps) {
  const [applyScrollClass, setApplyScrollClass] = useState(false);

  useScrollListener((scrollY, prevScrollY) => {
    if (scrollY === 0) {
      setApplyScrollClass(false);
    }
    if (scrollY > 0 && prevScrollY === 0) {
      setApplyScrollClass(true);
    }
  });

  return (
    <div className={styles.services} data-testid="header-desktop-services">
      <Center className={styles.servicesContent} maxInlineSize="min(var(--grid-wrap-fluid), var(--max-content-width))">
        <div className={styles.servicesLeft}>
          <NavigationMenu closeLabel={navigationConfig.menuCloseLabel} label={navigationConfig.menuLabel} />

          <span className={cx(styles.logoContainer, { [styles.scroll]: applyScrollClass })}>
            <div className={styles.divider} />
            <NavigationLogo logoName={navigationConfig.logoServices} size="sm" />
          </span>
        </div>

        <div className={styles.servicesRight} data-testid="header-desktop-services-right">
          <nav className={cx(styles.itemsContainer, { [styles.scroll]: applyScrollClass })}>
            {navigationItems.map(navigationItem => (
              <NavigationItem
                className={styles.serviceNavLink}
                key={navigationItem.uniqueId}
                navItem={navigationItem}
                trackingBlock="services-right"
              />
            ))}
          </nav>

          {subscriptionItem && (
            <span className={cx(styles.subscribeContainer, { [styles.scroll]: applyScrollClass })}>
              <NavigationItem navItem={subscriptionItem}>
                {({ href }) => <SubscriptionButton href={href} text={subscriptionItem.text} />}
              </NavigationItem>
            </span>
          )}

          <NavigationUserMenu loginUrl={loginUrl} items={userMenuItems} />
        </div>
      </Center>
    </div>
  );
}
